<template>
  <div id="dashboard" :class="{'mobile': isMobile}">
    <template v-if="isBOUser">
      <el-tabs v-model="activeTab" @tab-click="selectTab()" :stretch="isMobile">
        <el-tab-pane :label="$t('DASHBOARD')" name="dashboard"/>
        <el-tab-pane :label="$t('MEMBER_REPORT')" name="member-report"/>
        <el-tab-pane :label="$t('COIN_REPORT')" name="coin-report"/>
      </el-tabs>
    </template>

    <transition appear name="slide-from-right">
      <div :key="$route.fullPath" class="content">
        <dashboard v-if="activeTab === 'dashboard'"/>
        <member-report v-else-if="activeTab === 'member-report'"/>
        <coin-report v-else-if="activeTab === 'coin-report'"/>
      </div>
    </transition>
  </div>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin.js"
import Dashboard from "./components/dashboard"
import MemberReport from "./components/member-report"
import CoinReport from "./components/coin-report"

export default {
  name: "DashboardIndex",
  mixins: [generalMixin],
  components: {
    Dashboard,
    MemberReport,
    CoinReport
  },
  data() {
    return {
      activeTab: "dashboard",
    }
  },
  computed: {
    currentTabName() {
      switch(this.activeTab) {
        case 'dashboard': return this.$t("DASHBOARD")
        case 'member-report': return this.$t("MEMBER_REPORT")
        default: return this.$t("COIN_REPORT")
      }
    }
  },
  methods: {
    selectTab() {
      let chosenTab = this.activeTab
      this.$router.replace({ ...this.$router.currentRoute, query: {chosenTab} }).catch(() => {})
    }
  },
  mounted() {
    this.$store.dispatch("auth/getCurrentUserProfile") // refresh
    let chosenTab = this.$route.query?.chosenTab || 'dashboard'
    if (this.isBOUser == false) {
      chosenTab = 'dashboard'
    }
    this.activeTab = chosenTab
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/tabs.scss";

#dashboard {
  @include mobile-tabs;

  position: relative;
  .el-tabs__header {
    position: absolute;
    top: -4rem;
    left: 25rem;
    .el-tabs__item {
      font-size: 15px;
      line-height: 32px;
    }
  }
}
</style>