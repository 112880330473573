<template>
  <div id="coin-report">

    <table-action-row
      v-loading="isLoading"
      ref="table-action-row"
      :filterList="listQuery"
      @onFilterChange="value => { listQuery = value; handleFilter() }"
      :handleAction="() => getList(true)"
      actionButtonCode="EXPORT_TO_EXCEL"
      actionButtonCodeMobile="EXPORT"
      :filterKeyNameList="[
        'coinLog.userLevels',
        'coinLog.transactionTo',
        'dateFrom',
        'coinLog.logTypeList',
        'coinLog.transactionFrom', 'coinLog.isTransactionFromSystem'
      ]"
    >
      <!-- Filtering -->
      <template slot="filter-popover-inner">
        <el-form
          class="coin-report__filter-popover-inner-form"
          label-width="200px"
          :label-position="isMobile ? 'top' : 'left'"
          @submit.native.prevent="handleFilter"
          v-loading="isOptionLoading"
        >
          <el-form-item :label="$t('MEMBER_LEVEL')">
            <el-select v-model="listQuery.coinLog.userLevels" multiple placeholder="Select">
              <el-option 
                v-for="el in memberRoleList"
                :key="el.id"
                :label="el | translate" 
                :value="el.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Member Name">
            <el-input v-model="listQuery.coinLog.transactionTo" />
          </el-form-item>
          <el-form-item :label="$t('TRANSACTION_DATE')">
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              placement="bottom-start"
              :range-separator="$t('TO')"
              :start-placeholder="$t('START_DATE')"
              :end-placeholder="$t('END_DATE')"
              prefix-icon="custom-icon icon-calendar mini"
              :format="isMobile ? EL_DATE_FORMAT_MOBILE : EL_DATE_FORMAT"
              @focus="e => e.blur()"
            />
          </el-form-item>
          <el-form-item :label="$t('TRANSACTION_TYPE')">
            <el-select v-model="listQuery.coinLog.logTypeList" multiple placeholder="Select">
              <el-option 
                v-for="el in logTypeList"
                :key="el"
                :label="$t(el)" 
                :value="el"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('FROM')">
            <el-input v-model="listQuery.coinLog.transactionFrom" :disabled="listQuery.coinLog.isTransactionFromSystem">
              <el-select 
                slot="prepend" 
                v-model="listQuery.coinLog.isTransactionFromSystem"
                class="is-transaction-from-system-select"
              >
                <el-option :label="$t('SYSTEM')" :value="true"/>
                <el-option :label="$t('USER')" :value="false"/>
              </el-select>
            </el-input>
          </el-form-item>

          <el-row type="flex" justify="space-around" align="middle">
            <el-button class="w-25 button white" @click="handleReset()">{{ $t("CLEAR") }}</el-button>
            <el-button class="w-25 button primary" native-type="submit">{{ $t("APPLY") }}</el-button>
          </el-row>

        </el-form>
      </template>

    </table-action-row>

    <el-table
      v-loading="isLoading"
      class="newsily-bo-table"
      :data="list">
      <el-table-column :label="$t('FROM')">
        <template slot-scope="{row}">
          {{ row.fromUser ? getFullName(row.fromUser) : $t("SYSTEM") }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('TO')">
        <template slot-scope="{row}">
          {{ getFullName(row.toUser || row.owner) }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('TIME')">
        <template slot-scope="{row}">
          {{ row.createdDate != null ? moment(row.createdDate).format(DATE_TIME_FORMAT) : null }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('TRANSACTION_TYPE')"
        width="200"
      >
        <template slot-scope="{row}">
          {{ $t(row.logType) }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('NO_OF_COIN')" width="150">
        <template slot-scope="{row}">
          {{ parseCoin(row) }}
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-loading="isLoading"
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      @pagination="handleCurrentChange"
    />

  </div>
</template>

<script>
import { generalMixin } from '@/utils/general-mixin.js'
import TableActionRow from '@/components/table/table-action-row.vue'
import Pagination from '@/components/table/pagination.vue'
import cloneDeep from "lodash/cloneDeep"
import moment from 'moment'
import { PAGE_SIZES, ALL_LOG_TYPE } from "@/constants"
import { getRoleList } from "@/api/utility"
import { getCoinList } from "@/api/coin"

const emptyPhotoUrl = require('@/assets/pngs/profile-pic-placeholder.png')

const filterQuery = {
  page: 1,
  search: "",
  limit: PAGE_SIZES[0],
  dateFrom: "",
  dateTo: "",
  coinLog: {
    userLevels: [],
    logTypeList: [],
    transactionFrom: "",
    transactionTo: "",
    isTransactionFromSystem: false,
    showOnlyTransactionInFor: ["ADVERTISEMENT", "P2P_POST"]
  }
};

const allUserLevels = [
  "member_level_one",
  "member_level_two",
  "member_level_three",
  "member_level_four",
]

export default {
  name: "DashboardCoinReport",
  mixins: [generalMixin],
  components: {
    Pagination, TableActionRow
  },
  data() {
    return {
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      isOptionLoading: false,
      isLoading: false,
      total: 0,
      memberRoleList: [],
      logTypeList: cloneDeep(ALL_LOG_TYPE),
      // Data
      list: [],
      moment,
      emptyPhotoUrl,
    }
  },
  watch: {
    "listQuery.coinLog.isTransactionFromSystem": {
      handler(isSystem) {
        if (isSystem) this.listQuery.coinLog.transactionFrom = ""
      }
    }
  },
  computed: {
    dateRange: {
      get() { 
        return [moment(this.listQuery.dateFrom, this.DATE_FORMAT), moment(this.listQuery.dateTo, this.DATE_FORMAT)] 
      },
      set(value) {
        if (value != null) {
          this.listQuery.dateFrom = moment(value[0]).format(this.DATE_FORMAT)
          this.listQuery.dateTo = moment(value[1]).format(this.DATE_FORMAT)
        } else {
          this.listQuery.dateFrom = ""
          this.listQuery.dateTo = ""
        }
      }
    }
  },
  methods: {
    // REDIRECTION
    handleReset() {
      this.listQuery = cloneDeep(filterQuery)
      this.handleFilter();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page
      this.listQuery.limit = val.limit
      this.getList()
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
      this.$nextTick(() => this.$refs['table-action-row'].refreshActiveFilterNum())
    },
    parseCoin(row) {
      if (row.transactionType == "OUT") {
        return "-" + row.coin
      } else {
        return row.coin
      }
      
    },
    async getAllOptions() {
      this.isOptionLoading = true
      const promises = [
        this.getMemberRoleList()
        // add more if needed in future
      ]
      await Promise.all(promises)
      this.isOptionLoading = false
      
    },
    async getMemberRoleList() {
      await getRoleList().then(res => {
        if (res.status == 'ok') {
          this.memberRoleList = res.result.list.filter(el => el.code.includes("member"))
        }
      })
    },
    getList(isExport = false) {
      // console.log('Sending: ', this.listQuery)
      this.isLoading = true
      const postData = cloneDeep(this.listQuery)
      if (postData.coinLog.userLevels.length === 0) {
        postData.coinLog.userLevels = cloneDeep(allUserLevels)
      }
      if (postData.coinLog.logTypeList.length === 0) {
        postData.coinLog.logTypeList = cloneDeep(ALL_LOG_TYPE)
      }
      if (postData.coinLog.transactionTo === "") {
        delete postData.coinLog.transactionTo
      }
      if (postData.coinLog.transactionFrom === "") {
        delete postData.coinLog.transactionFrom
      }
      postData.exportExcel = isExport

      getCoinList(postData)
      .then(res => {
        if (res.status != 'ok') return
        
        if (isExport) {
          window.open(res.result.fileURL);
        } else {
          // console.log('Got: ', res)
          this.list = cloneDeep(res.result.list)
          this.total = res.result.total

          // Back to previous page if empty data page
          const page = res.result.page
          const finalPage = res.result.finalPage
          if (finalPage < page) {
            this.listQuery.page = finalPage;
            this.getList();
          }
        }

      })
      .catch(this.handleError)
      .finally(() => this.isLoading = false)
    },
  },
  async mounted() {
    this.getList()
    this.getAllOptions()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/table.scss";
#coin-report {
  @include newsily-bo-table;
  .icon-edit {
    float: right;
  }

}
.coin-report__filter-popover-inner-form {
  .el-input-group__prepend {
    padding: 0;
    .is-transaction-from-system-select.el-select {
      margin-left: 0;
      margin-right: 0;
      * {
        cursor: pointer;
      }
      .el-input {
        width: 6rem;
      }
    }
  }
}
</style>