<template>
  <div id="bo-dashboard">
    <el-row 
      :type="isMobile ? '' : 'flex'"
      justify="space-between" 
      align="start" 
      :gutter="40"
    >
      <el-col>
        <div class="bg-white h-100">
          <h3>{{ isBOUser ? $t("USER_DETAILS") : $t("MEMBER_DETAILS") }}</h3>
          <el-row 
            :type="isMobile ? '' : 'flex'"
            justify="space-between" 
            align="start" 
            class="w-100"
          > 
            <el-col :span="isMobile ? 24 : 6">
              <h4>{{ $t("PROFILE_PIC") }}</h4>
              <div class="image-container">
                <el-image
                  v-if="displayPhotoUrl"
                  :src="displayPhotoUrl"
                  fit="contain"
                  alt="profile picture"
                />
              </div>
            </el-col>
            <el-col :span="isMobile ? 24 : 18">
              <h4 class="member-info-header">{{ $t("INFO") }}</h4>
              <div class="member-detail">
                <el-row type="flex" justify="space-between" align="middle" >
                  <el-row type="flex" justify="space-between" align="middle">
                    {{ getFullName(user) }}
                    <i
                      class="custom-icon level-icon mini"
                      :class="roleLevelIcon"
                    />
                  </el-row>
                  <i 
                    @click="goTo('/faq')"
                    class="custom-icon icon-info mini pointer"
                  />
                </el-row>
                <el-row class="accessible-row" :type="isMobile ? '' : 'flex'" justify="space-between">
                  <el-col :span="isMobile ? 24 : 9">
                    Accessible to:
                  </el-col>
                  <el-col :span="isMobile ? 24 : 15">
                    <el-row 
                      type="flex" 
                      justify="start" 
                      align="middle"
                      v-for="el in accessibleList"
                      :key="el.labelCode"
                      class="access-row"
                    >
                      <i 
                        class="custom-icon access-icon mini"
                        :class="{
                          'icon-access-tick': el.canAccess !== false,
                          'icon-access-cross': el.canAccess === false
                        }"
                      />
                      {{ $t(el.labelCode, el.labelObj) }}
                    </el-row>
                  </el-col>
                </el-row>
                <template v-if="user.coinsRequiredToLevelUp">
                  <el-divider/>
                  <el-row type="flex" justify="center">
                    <i class="custom-icon grey-icon icon-lock mini" />
                    <span v-html="$t('EARN_X_COIN_TO_LVL_UP', { coins: user.coinsRequiredToLevelUp, nextLevel })" />
                    <span></span>
                  </el-row>
                </template>
              </div>
              <el-row type="flex" justify="center" align="middle">
                <el-button class="button white view-profile-btn" @click="goTo('/bo/profile')">
                  {{ $t("VIEW_PROFILE") }}
                </el-button>
              </el-row>
              
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col class="coin-container">
        <div class="bg-white h-100">
          <h3>{{ $t("WALLET") }}</h3>
          <el-row type="flex" justify="space-between" align="middle" class="coin-detail">
            <div>
              <el-row type="flex" justify="start" align="middle">
                <i class="custom-icon icon-money-bag large" />
                <div>
                  <span>{{ $t("COIN_ACCUMULATED") }}</span>
                  <br/>
                  <span class="text-grey">{{ user.accumulatedCoins }}</span>
                </div>
              </el-row>
            </div>
            <div>
              <el-row type="flex" justify="start" align="middle">
                <i class="custom-icon icon-wallet large" />
                <div>
                  <span>{{ $t("COIN_AVAILABLE") }}</span>
                  <br/>
                  <span class="text-grey">{{ user.availableCoins }}</span>
                  <template v-if="user.coinOnSale">
                     / <span class="text-primary text-bold">({{ user.coinOnSale }})</span>
                  </template>
                </div>
              </el-row>
            </div>
          </el-row>
          <el-row type="flex" justify="space-between" align="middle" class="coin-detail">
            <div>
              <el-row type="flex" justify="start" align="middle">
                <i class="custom-icon icon-coin-on-sale large" />
                <div>
                  <span>{{ $t("COIN_ON_SALE") }}</span>
                  <br/>
                  <span class="text-grey">{{ user.coinOnSale }}</span>
                </div>
              </el-row>
            </div>
            <div>
              <el-row type="flex" justify="start" align="middle">
                <i class="custom-icon icon-trade large" />
                <div>
                  <span>{{ $t("TRADE") }}</span>
                  <br/>
                  <span class="text-primary text-bold">{{ user.coinOnReserved }}</span>
                </div>
              </el-row>
            </div>
          </el-row>
        </div>
      </el-col>
    </el-row>

    <el-row type="flex" justify="space-between" align="start" :gutter="40" class="ads-detail">
      <el-col>
        <div class="bg-white h-100">
          <h3>{{ $t("ADVERTISE_MANAGEMENT") }}</h3><br />
          
          <el-table v-loading="isLoading" class="newsily-bo-table" :data="list"
          @row-click="handleRowClick">
            <el-table-column prop="position" :label="$t('TYPE')" :width="isMobile ? '200' : ''">
              <template slot-scope="{ row }">
                {{ $t(row.position) }}
              </template>
            </el-table-column>
            <el-table-column prop="coin" :label="$t('COST')" :width="isMobile ? '80' : '100'" />
            <el-table-column prop="fromDate" :label="$t('START_DATE')" width="100">
              <template slot-scope="{ row }">
                {{ moment(row.fromDate).format(DATE_FORMAT) }}
              </template>
            </el-table-column>
            <el-table-column prop="toDate" :label="$t('END_DATE')" width="100">
              <template slot-scope="{ row }">
                {{ moment(row.toDate).format(DATE_FORMAT) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('ARTICLE_NAME')">
              <template slot-scope="{ row }">
                {{ row.article.name | translate("-") }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('ARTICLE_LINK')" width="150">
              <template slot-scope="{ row }">
                <el-link
                  type="primary"
                  @click.stop="goToArticle(row.article,true)"
                >
                  {{ $t("CLICK_TO_OPEN") }}
                </el-link>
                <i
                  class="custom-icon mini pointer icon-copy"
                  @click.stop="copyArticleLink(row.article)"
                />
              </template>
            </el-table-column>
            <el-table-column prop="status" :label="$t('STATUS')" width="100">
              <template slot-scope="{ row }">
                <div>
                  <span :class="getStatusClass(row.status)">{{
                    $t(row.status)
                  }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <br>
          <el-row type="flex" justify="center" align="middle">
            <el-button class="button white" @click="goTo('/bo/advertisement')">
              {{ $t("VIEW_ALL") }}
            </el-button>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
const emptyPhotoUrl = require('@/assets/pngs/profile-pic-placeholder.png')

import moment from "moment";
import { mapGetters } from "vuex"
import { generalMixin } from "@/utils/general-mixin.js"
import { getAdsList } from "@/api/advertisement";

export default {
  name: "Dashboard",
  mixins: [generalMixin],
  computed: {
    ...mapGetters(["user"]),
    displayPhotoUrl() {
      let result = this.user?.photo?.upload?.url
      if (!result) result = emptyPhotoUrl
      return result
    },
    accessibleList() {
      let list = []
      if (this.isMember) {
        list = [
          { labelCode: "ADD_ARTICLE", canAccess: false },
          { 
            labelCode: "COIN_EARN_MULTIPLIER_BY", 
            labelObj: { amount: this.user?.multiplier },
            canAccess: true
          }
        ]
        if (this.isMemberThree || this.isMemberFour) {
          list.forEach(el => {
            el.canAccess = true
          })
        }
      } else {
        list = [
          { labelCode: "ARCHIVE", canAccess: this.isAdmin },
          { labelCode: "ADD_OR_EDIT", canAccess: this.fullAccess },
          { labelCode: "VIEW", canAccess: this.isBOUser }
        ]
      }
      return list
    },
    nextLevel() {
      if (this.isMemberOne) return "2"
      else if (this.isMemberTwo) return "3"
      else if (this.isMemberThree) return "4"
      else return null
    },
    roleLevelIcon() {
      if (this.isMemberOne) return "icon-member-level-one"
      else if (this.isMemberTwo) return "icon-member-level-two"
      else if (this.isMemberThree) return "icon-member-level-three"
      else if (this.isMemberFour) return "icon-member-level-four"
      else if (this.isAdmin) return "icon-role-admin"
      else if (this.isManager) return "icon-role-manager"
      else if (this.isOfficer) return "icon-role-officer"
      else return null
    }
  },
  data() {
    return {
      isLoading: false,
      list: [],
      moment: moment,
    }
  },
  methods: {
    getList() {
      this.isLoading = true;
      const filterQuery = {
        limit: 3, // Can change depends on UI
        showArchived: false,
        advertisement: {
          user: {
            id: this.user.id,
          },
        },
      };
      getAdsList(filterQuery).then((res) => {
          if (res.status != "ok") return;
          // console.log("Got: ", res);
          this.list = res.result.list;
        })
        .catch(this.handleError)
        .finally(() => (this.isLoading = false));
    },
    handleRowClick(row, column) {
      if (column.property == 'actions' || column.property == 'link') return
      this.goTo(`/bo/advertisement/detail/0/${row.id}/0`)
    },
  },
  mounted() {
    // console.log(this.user)
    this.getList()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/table.scss";

#bo-dashboard {
  @include newsily-bo-table;

  >.el-row>.el-col {
    >div {
      border-radius: 10px;
      padding: 1rem 1.5rem;
    }
  }
  h4 {
    margin: 1rem 0;
  }
  .image-container {
    width: 109px;
    height: 109px;
    border-radius: 50%;
    border: 3px dashed $primary;
    img {
      width: 99px;
      height: 99px;
      border-radius: 50%;
      margin-left: 2px;
      margin-top: 2px;
    }
  }
  .member-info-header {
    margin: 1rem 1.5rem;
  }
  .member-detail,
  .coin-detail {
    height: fit-content;
    border-radius: 15px;
    background-color: #F3F5FA;
    padding: 1rem 0;
    margin: 0 auto 1rem;
  }
  .coin-container {
    h3 + div {
      margin-top: 3rem;
    }
  }
  .member-detail {
    padding: 1.5rem;
    * {
      font-family: inherit;
    }
    .level-icon {
      margin-left: 10px;
    }
    .access-icon,
    .icon-lock {
      margin-right: 10px;
    }
    .access-row + .access-row {
      margin-top: 10px;
    }
    >.el-row + .el-row {
      margin-top: 2rem;
    }
  }
  .coin-detail {
    width: 100%;
    .custom-icon {
      background-color: $white;
      margin-right: 1rem;
      border-radius: 11px;
      width: 62px;
      height: 62px;
      >i {
        margin: auto;
      }
    }
    >div:first-child {
      border-right: 1px solid #BCC1DA;
    }
    >div {
      width: 100%;
      margin-left: 2rem;
      line-height: 35px;
      height: 70%;
    }
  }
  .ads-detail {
    margin-top: 1rem;
  }
  .view-profile-btn {
    margin-top: 1rem;
  }
  .icon-coin-on-sale,
  .icon-trade {
    background-size: 100px;
  }
}

.mobile #bo-dashboard {
  >.el-row >.el-col {
    margin-bottom: 1rem;
    .member-detail {
      >.el-row + .el-row {
        margin-top: 0;
      }
      .accessible-row {
        .el-col {
          margin-top: 10px;
        }
      }
    }
    .coin-detail {
      font-size: 0.8rem;
      padding: 10px 0;
      >div {
        margin-left: 10px;
        margin-right: 5px;
      }
    }
  }
}
</style>