<template>
  <div id="member-report">

    <table-action-row
      v-loading="isLoading"
      ref="table-action-row"
      :filterList="listQuery"
      @onFilterChange="value => { listQuery = value; handleFilter() }"
      :handleAction="() => getList(true)"
      actionButtonCode="EXPORT_TO_EXCEL"
      actionButtonCodeMobile="EXPORT"
      :filterKeyNameList="[
        'user.userLevels',
        'user.dateFrom',
        'user.status',
        'user.coinFrom',
        'user.coinTo',
        'user.accumulatedCoinsFrom',
        'user.accumulatedCoinsTo',
      ]"
    >
      <!-- Filtering -->
      <template slot="filter-popover-inner">
        <el-form
          ref="filter-form"
          label-width="200px"
          :label-position="isMobile ? 'top' : 'left'"
          @submit.native.prevent="handleFilterChange"
          :rules="rules"
          v-loading="isOptionLoading"
          :model="listQuery"
        >
          <el-form-item :label="$t('MEMBER_LEVEL')">
            <el-select v-model="listQuery.user.userLevels" multiple :placeholder="$t('SELECT')">
              <el-option 
                v-for="el in memberRoleList"
                :key="el.id"
                :label="el | translate" 
                :value="el.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('REGISTERED_DATE')">
            <el-date-picker
              v-model="listQueryUserDateRange"
              type="daterange"
              placement="bottom-start"
              :range-separator="$t('TO')"
              :start-placeholder="$t('START_DATE')"
              :end-placeholder="$t('END_DATE')"
              prefix-icon="custom-icon icon-calendar mini"
              :format="isMobile ? EL_DATE_FORMAT_MOBILE : EL_DATE_FORMAT"
              @focus="e => e.blur()"
            />
          </el-form-item>
          <el-form-item :label="$t('MEMBER_STATUS')">
            <el-select 
              v-model="listQuery.user.status" 
              :placeholder="$t('SELECT')"
              :class="getStatusClass(listQuery.user.status)"
            >
              <el-option 
                v-for="el in [...allSelectableStatusListFor(), { value: null }]"
                :key="el.value"
                :label="el.value ? $t(el.value) : $t('ALL')" 
                :value="el.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('AVAILABLE_COIN_FROM')" prop="coinFrom">
            <el-input-number 
              v-model="listQuery.user.coinFrom" 
              :min="0"
            />
          </el-form-item>
          <el-form-item :label="$t('AVAILABLE_COIN_TO')" prop="coinTo">
            <el-input-number 
              v-model="listQuery.user.coinTo" 
              :min="listQuery.user.coinFrom"
            />
          </el-form-item>

          <el-form-item :label="$t('ACCUMULATED_COIN_FROM')" prop="accumulatedCoinsFrom">
            <el-input-number 
              v-model="listQuery.user.accumulatedCoinsFrom" 
              :min="0"
            />
          </el-form-item>
          <el-form-item :label="$t('ACCUMULATED_COIN_TO')" prop="accumulatedCoinsTo">
            <el-input-number 
              v-model="listQuery.user.accumulatedCoinsTo" 
              :min="listQuery.user.accumulatedCoinsFrom"
            />
          </el-form-item>

          <el-row type="flex" justify="space-around" align="middle">
            <el-button class="w-25 button white" @click="handleReset()">{{ $t('CLEAR') }}</el-button>
            <el-button class="w-25 button primary" @click="handleFilter()">{{ $t('APPLY') }}</el-button>
          </el-row>

        </el-form>
      </template>

    </table-action-row>

    <el-table
      v-loading="isLoading"
      class="newsily-bo-table"
      :data="list">
      <el-table-column :label="$t('NAME')">
        <template slot-scope="{row}">
          {{ getFullName(row) }}
        </template>
      </el-table-column>
      <el-table-column prop="email" :label="$t('EMAIL')" />
      <el-table-column :label="$t('USER_ROLE')">
        <template slot-scope="{row}">
          {{ row.role | translate }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('PHOTO')">
        <template slot-scope="{row}">
          <el-avatar 
            :src="row.photo ? row.photo.upload.url : emptyPhotoUrl"
          />
        </template>
      </el-table-column>
      <el-table-column prop="phone" :label="$t('PHONE')" />
      <el-table-column prop="address" :label="$t('ADDRESS')" />
      <el-table-column prop="availableCoins" :label="$t('COIN_BALANCE')" />
      <el-table-column prop="accumulatedCoins" :label="$t('COIN_ACCUMULATED')" />
      <el-table-column :label="$t('REGISTERED_DATE')">
        <template slot-scope="{row}">
          {{ row.createdDate != null ? moment(row.createdDate).format(DATE_FORMAT) : null }}
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-loading="isLoading"
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      @pagination="handleCurrentChange"
    />

  </div>
</template>

<script>
import { generalMixin } from '@/utils/general-mixin.js'
import TableActionRow from '@/components/table/table-action-row.vue'
import Pagination from '@/components/table/pagination.vue'
import cloneDeep from "lodash/cloneDeep"
import moment from 'moment'
import { PAGE_SIZES } from "@/constants"
import { getUserList } from "@/api/user"
import { getRoleList } from "@/api/utility"

const emptyPhotoUrl = require('@/assets/pngs/profile-pic-placeholder.png')

const filterQuery = {
  page: 1,
  search: "",
  limit: PAGE_SIZES[0],
  user: {
    status: null,
    dateFrom: "",
    dateTo: "",
    coinFrom: 0,
    coinTo: 0,
    accumulatedCoinsFrom: 0,
    accumulatedCoinsTo: 0,
    userLevels: [],
  }
};

const allUserLevels = [
  "member_level_one",
  "member_level_two",
  "member_level_three",
  "member_level_four",
]

export default {
  name: "DashboardMemberReport",
  mixins: [generalMixin],
  components: {
    Pagination, TableActionRow
  },
  data() {
    return {
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      isOptionLoading: false,
      isLoading: false,
      total: 0,
      memberRoleList: [],
      rules: {
        coinFrom: [{ validator: this.validateAvailableCoin, trigger: "blur" }],
        coinTo: [{ validator: this.validateAvailableCoin, trigger: "blur" }],
        accumulatedCoinsFrom: [{ validator: this.validateAccCoin, trigger: "blur" }],
        accumulatedCoinsTo: [{ validator: this.validateAccCoin, trigger: "blur" }],
      },
      // Data
      list: [],
      moment,
      emptyPhotoUrl,
    }
  },
  computed: {
    searchKeyword: {
      get() { return this.listQuery.search },
      set(value) {
        this.listQuery.search = value
      }
    },
    listQueryUserDateRange: {
      get() { 
        return [moment(this.listQuery.user.dateFrom, this.DATE_FORMAT), moment(this.listQuery.user.dateTo, this.DATE_FORMAT)] 
      },
      set(value) {
        if (value != null) {
          this.listQuery.user.dateFrom = moment(value[0]).format(this.DATE_FORMAT)
          this.listQuery.user.dateTo = moment(value[1]).format(this.DATE_FORMAT)
        } else {
          this.listQuery.user.dateFrom = ""
          this.listQuery.user.dateTo = ""
        }
      }
    }
  },
  methods: {
    // REDIRECTION
    goToEdit(id) {
      this.goTo(`/bo/user/detail/${id}`)
    },
    handleReset() {
      this.listQuery = cloneDeep(filterQuery)
      this.handleFilter();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch
      if (this.showSearch == false) {
        this.searchKeyword = ""
        this.getList()
      }
    },
    handleCurrentChange(val) {
      this.listQuery.page = val.page
      this.listQuery.limit = val.limit
      this.getList()
    },
    handleFilter() {
      this.$refs["filter-form"].validate(valid => {
        if (valid) {
          this.listQuery.page = 1;
          this.getList();
          this.$nextTick(() => this.$refs['table-action-row'].refreshActiveFilterNum())
        }
      })
    },
    validateAvailableCoin(_, __, callback) {
      const min = this.listQuery.user.coinFrom
      const max = this.listQuery.user.coinTo
      if (max != null && min != null && max < min) {
        callback(new Error(this.$t("FROM_MORE_THAN_TO")))
      } else {
        callback()
      }
    },
    validateAccCoin(_, __, callback) {
      const min = this.listQuery.user.accumulatedCoinsFrom
      const max = this.listQuery.user.accumulatedCoinsTo
      if (max != null && min != null && max < min) {
        callback(new Error(this.$t("FROM_MORE_THAN_TO")))
      } else {
        callback()
      }
    },
    async getAllOptions() {
      this.isOptionLoading = true
      const promises = [
        this.getMemberRoleList()
        // add more if needed in future
      ]
      await Promise.all(promises)
      this.isOptionLoading = false
      
    },
    async getMemberRoleList() {
      await getRoleList().then(res => {
        if (res.status == 'ok') {
          this.memberRoleList = res.result.list.filter(el => el.code.includes("member"))
        }
      })
    },
    getList(isExport = false) {
      // console.log('Sending: ', this.listQuery)
      this.isLoading = true
      const postData = cloneDeep(this.listQuery)
      if (postData.user.userLevels.length === 0) {
        postData.user.userLevels = cloneDeep(allUserLevels)
      }
      if (postData.user.coinTo === 0) delete postData.user.coinTo
      if (postData.user.accumulatedCoinsTo === 0) delete postData.user.accumulatedCoinsTo
      postData.exportExcel = isExport

      getUserList(postData)
      .then(res => {
        if (res.status != 'ok') return

        // console.log('Got: ', res)
        if (isExport) {
          window.open(res.result.fileURL);
        } else {
          this.list = cloneDeep(res.result.list)
          this.total = res.result.total
  
          // Back to previous page if empty data page
          const page = res.result.page
          const finalPage = res.result.finalPage
          if (finalPage < page) {
            this.listQuery.page = finalPage;
            this.getList();
          }
        }

      })
      .catch(this.handleError)
      .finally(() => this.isLoading = false)
    }
  },
  async mounted() {
    this.getList()
    this.getAllOptions()
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/table.scss";
#member-report {
  @include newsily-bo-table;
  .icon-edit {
    float: right;
  }

}
</style>